import Config from "../config.json";

export function stringToPrice(string) {
  return (
    "€ " +
    parseFloat(string ?? 0)
      .toFixed(2)
      .replace(".", ",")
  );
}

export function buildExpirationMessage(date, contrastTheme) {
  let expirationMessage;
  let expirationClassName;
  if (date) {
    const isToday = (date) => {
      const today = new Date();
      return (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
      );
    };
    const expirationDate = new Date(date);
    expirationMessage = "Termina ";
    if (isToday(expirationDate)) {
      expirationMessage += "oggi";
      expirationClassName = contrastTheme
        ? "theme-product-expiration-expiring-contrast"
        : "theme-product-expiration-expiring";
    } else {
      expirationMessage +=
        "il " +
        expirationDate.toLocaleString("it-IT", {
          month: "numeric",
          day: "2-digit",
        });
      expirationClassName = contrastTheme
        ? "theme-product-expiration-contrast"
        : "theme-product-expiration";
    }
    expirationMessage +=
      " alle " +
      expirationDate.toLocaleString("it-IT", {
        timeStyle: "short",
      });
  }
  return [expirationMessage, expirationClassName];
}

// Removes keys not handled by this form to avoid errors on update
export function cleanAddress(address) {
  delete address.email;
  delete address.phone;
  delete address.first_name;
  delete address.last_name;
}

export function printEmailTo() {
  let mail;
  mail = Config.mail;
  return "mailto:"+mail;
}

export function formatPageTitle(title) {
  let pagePrefix;
  /*
  switch (Config.activeTheme) {
    case "additional":
      pagePrefix = "FineSip";
      break;
    case "vendor":
      pagePrefix = "Bicerin";
      break;
    default:
      pagePrefix = "FlashWine";
      break;
  }
  */
  pagePrefix = Config.projectName;
  return title ? pagePrefix + " | " + title : pagePrefix;
}
