import SectionTitle from "../components/layout/section-title";
//import { useHistory } from "react-router-dom";
//import { path as landingPath } from "./landing-page";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { formatPageTitle,printEmailTo } from "../service/formatting-service";

export const path = "/pages/order-confirmed";
export default function OrderConfirmedPage({ location, setCloseableOnMobile }) {
  //const history = useHistory();

  useEffect(() => setCloseableOnMobile(false), [setCloseableOnMobile]);

  /*
  useEffect(() => {
    if (!location.state) {
      // Returns to the landing (e.g. a page refresh)
      history.replace(landingPath);
    }
  }, [history, location]);
  */
  return (
    <>
      <Helmet>
        <title>{formatPageTitle("Ordine confermato")}</title>
        <meta name="robots" content="noindex, nofollow" />
        <meta name="description" content="Ordine confermato" />
        <meta property="og:title" content={formatPageTitle("Ordine confermato")} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content="Ordine confermato" />
      </Helmet>
      <SectionTitle title="Ordine confermato" />
      <div className="row justify-content-center">
        <h2 className="col col-md-10 fw-600 font-18-24 theme-color-primary">
          Il tuo ordine è stato confermato
        </h2>
      </div>
      <div className="row justify-content-center">
        <p className="col col-md-10 fw-600 text-gray-2 font-16">
          Abbiamo ricevuto il tuo ordine. Scrivi <a href={printEmailTo()}>QUI</a> per concordare i termini di pagamento e spedizione con il nostro staff.
        </p>
      </div>
    </>
  );
}
